import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  Signal,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { FooterOverlayDynamicService } from '@app/overlays/footer-overlay/services/footer-overlay-dynamic.service';
import { SentryUtil } from '@app/shared/utils/sentry.util';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject, map, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { ArrowDown1IconComponent } from '../../shared/components/icons/generated/arrow-down-1/arrow-down-1.component';
import {
  AVAILABLE_LANGUAGE_OPTION,
  AVAILABLE_LOCATION_OPTION,
} from '@app/akita/api/products/models/products.state';
import { LanguageOption, LocationOption } from '@app/akita/router/models/router.state';
import { toSignal } from '@angular/core/rxjs-interop';
import { ScreenSizeInfoState } from '@app/akita/screen/state/screen.store';
import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { AkitaNewsletterService } from '@app/akita/api/newsletter/state/newsletter.service';
import { AkitaRemoteConfigQuery } from '@app/akita/api/remote-config/state/remote-config.query';
import { NewsletterSubtitle } from '@app/akita/api/remote-config/models/newsletter-subtitle.model';
import { CookieService } from '@app/shared/services/cookie.service';

const SCROLL_OFFSET = 125;

@Component({
  selector: 'app-location-picker-bar',
  templateUrl: './location-picker-bar.template.html',
  styleUrls: ['./location-picker-bar.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, ArrowDown1IconComponent, AsyncPipe],
})
export class LocationPickerBarComponent implements AfterViewInit, OnDestroy {
  public hideBar: WritableSignal<boolean> = signal(false);

  public country: string;

  public rtl: Signal<boolean>;
  public biggerThan: Signal<ScreenSizeInfoState>;

  public announcementBarText: Signal<NewsletterSubtitle | null> = signal(null);
  public showAnnouncementBar = true;

  public selectedLanguage$: Observable<LanguageOption>;
  public languages: Array<LanguageOption> = AVAILABLE_LANGUAGE_OPTION;

  private readonly selectedLocationSubject: BehaviorSubject<LocationOption>;
  public readonly selectedLocation$: Observable<LocationOption>;
  public locations: Array<LocationOption> = AVAILABLE_LOCATION_OPTION;

  @ViewChild('languagePicker', { read: ElementRef })
  public languagePickerElem?: ElementRef | null;

  @ViewChild('locationPicker', { read: ElementRef })
  public locationPickerElem?: ElementRef | null;

  private readonly destroySubject = new Subject<void>();
  private subscriptions = new Subscription();

  constructor(
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly translateService: TranslocoService,
    private readonly footerOverlayDynamicService: FooterOverlayDynamicService,
    private readonly akitaNewsletterService: AkitaNewsletterService,
    private readonly akitaRemoteConfigQuery: AkitaRemoteConfigQuery,
    private readonly cookieService: CookieService
  ) {
    this.selectedLanguage$ = this.translateService.langChanges$.pipe(
      map(this.mapLangToLanguageOption.bind(this))
    );

    this.showAnnouncementBar = this.cookieService.check('popsy_newsletter_subscribed');

    this.rtl = toSignal(this.akitaRouterQuery.selectIsRtlLayout(), {
      initialValue: false,
    });
    this.biggerThan = toSignal(this.akitaScreenQuery.selectBiggerThan(), {
      initialValue: {} as ScreenSizeInfoState,
    });

    this.announcementBarText = toSignal(
      this.akitaRemoteConfigQuery.selectAnnouncementBarText(),
      { initialValue: null }
    );

    this.selectedLocationSubject = new BehaviorSubject(false as any);
    this.selectedLocation$ = this.selectedLocationSubject.asObservable();

    this.selectedLocation$ = this.akitaRouterQuery
      .selectCountry()
      .pipe(map(this.mapCountryToLocationOption.bind(this)));

    this.country = this.akitaRouterQuery.country.toUpperCase();
  }

  public ngAfterViewInit(): void {
    window.addEventListener('scroll', this.onScroll, true);
  }

  public onScroll = (event: any) => {
    if (event?.target?.className === 'main-scroll') {
      if (event?.target?.scrollTop > SCROLL_OFFSET) {
        this.hideBar.set(true);
      } else {
        this.hideBar.set(false);
      }
    }
  };

  private mapLangToLanguageOption(lang: string): LanguageOption {
    let selectedLang = this.languages[0];
    if (lang) {
      for (const language of this.languages) {
        if (language.locale === lang) {
          selectedLang = language;
          break;
        }
      }
    }
    return selectedLang;
  }

  private mapCountryToLocationOption(country: string): LocationOption {
    let selectedLocation = this.locations[0]; // SA default fall-back

    if (country) {
      for (const location of this.locations) {
        if (location.country === country.toLowerCase()) {
          selectedLocation = location;
          break;
        }
      }
    }
    this.selectedLocationSubject.next(selectedLocation);
    return selectedLocation;
  }

  public toggleLocationPicker(): boolean {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
      this.subscriptions = new Subscription();
    }

    this.subscriptions = this.footerOverlayDynamicService.observeLanguage
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: () => {},
      });

    const selectedLocation = this.selectedLocationSubject.value;

    this.footerOverlayDynamicService.open('language', {
      elementRef: this.locationPickerElem,
      data: {
        placeholder: 'button_select_location',
        selectedOption: selectedLocation,
        options: this.locations,
        urlParam: 'country',
        urlValueKey: 'country',
        changeUrlFragment: true,
      },
      isBrowser: this.akitaRouterQuery.isBrowser,
    });

    SentryUtil.addBreadcrumb({
      category: 'ui',
      message: `Opened "Language Overlay" modal`,
      level: 'info',
      type: 'user',
    });

    return false;
  }

  public toggleLanguagePicker(): boolean {
    const selectedLanguage = this.mapLangToLanguageOption(
      this.translateService.getActiveLang()
    );
    this.footerOverlayDynamicService.open('language', {
      elementRef: this.locationPickerElem,
      data: {
        placeholder: 'label_select_language',
        selectedOption: selectedLanguage,
        options: this.languages,
        urlParam: 'locale',
        urlValueKey: 'locale',
        changeUrlFragment: true,
      },
      isBrowser: this.akitaRouterQuery.isBrowser,
    });

    SentryUtil.addBreadcrumb({
      category: 'ui',
      message: `Opened "Language Overlay" modal`,
      level: 'info',
      type: 'user',
    });

    return false;
  }

  public getCountryFlag(country: string): string {
    return `/assets/flags/square/${country.toLowerCase()}-square.svg`;
  }

  public openNewsletterDialog(): void {
    this.akitaNewsletterService.openUpNewsletter();
  }

  public ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }
}
