import { Injectable, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private readonly document: Document | null;
  private readonly documentIsAccessible: boolean;

  constructor(
    private readonly injector: Injector,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    this.documentIsAccessible = this.akitaRouterQuery.isBrowser;
    if (this.documentIsAccessible) {
      this.document = this.injector.get<Document | null>(DOCUMENT, null);
    } else {
      this.document = null;
    }
  }

  /**
   * @param name Cookie name
   * @returns {boolean}
   */
  public check(name: string): boolean {
    if (!this.documentIsAccessible) {
      return false;
    }

    name = encodeURIComponent(name);

    const regExp: RegExp = this.getCookieRegExp(name);
    return regExp.test(this.document ? this.document.cookie : '');
  }

  /**
   * Gets a cookie (this gets one random cookie, if multiple are there)
   * @param name Cookie name
   * @returns {any}
   * @deprecated Use getLatest instead
   */
  public get(name: string): string {
    if (this.documentIsAccessible && this.check(name)) {
      name = encodeURIComponent(name);

      const regExp: RegExp = this.getCookieRegExp(name);
      const result: RegExpExecArray | null = regExp.exec(
        this.document ? this.document.cookie : ''
      );

      return decodeURIComponent(result ? result[1] : '');
    } else {
      return '';
    }
  }

  /**
   * Gets the latest Cookie (there can be duplicated cookies)
   * @param name Cookie name
   * @returns {any}
   */
  public getLatest(name: string): string {
    if (this.documentIsAccessible) {
      const allCookies = this.getAll();
      name = encodeURIComponent(name);
      const cookieValue: string = allCookies[name] as string;
      if (cookieValue) {
        return decodeURIComponent(cookieValue);
      }
    }
    return '';
  }

  /**
   * @returns Record<string, unknown>
   */
  public getAll(): Record<string, unknown> {
    if (!this.documentIsAccessible) {
      return {};
    }

    const cookies: {
      [key: string]: string;
    } = {};
    const document: any = this.document;

    if (document.cookie && document.cookie !== '') {
      const split: Array<string> = document.cookie.split(';');

      for (let i = 0; i < split.length; i += 1) {
        const currentCookie: Array<string> = split[i].split('=');

        currentCookie[0] = currentCookie[0].replace(/^ /, '');
        const decoddedCurrentCookie = decodeURIComponent(currentCookie[0]);
        cookies[decoddedCurrentCookie] = decodeURIComponent(currentCookie[1]);
      }
    }

    return cookies;
  }

  /**
   * @param name     Cookie name
   * @param value    Cookie value
   * @param expires  Number of days until the cookies expires or an actual `Date`
   * @param path     Cookie path
   * @param domain   Cookie domain
   * @param secure   Secure flag
   * @param sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `None`
   */
  public set(
    name: string,
    value: string,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite: 'Lax' | 'None' | 'Strict' = 'None'
  ): void {
    if (!this.documentIsAccessible || !this.document) {
      return;
    }

    let cookieString: string =
      encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';

    console.log('cookieString', cookieString);

    if (expires) {
      if (typeof expires === 'number') {
        const dateExpires: Date = new Date(
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          new Date().getTime() + expires * 1000 * 60 * 60 * 24
        );

        cookieString += 'expires=' + dateExpires.toUTCString() + ';';
      } else {
        cookieString += 'expires=' + expires.toUTCString() + ';';
      }
    }

    if (path) {
      cookieString += 'path=' + path + ';';
    }

    if (domain) {
      cookieString += 'domain=' + domain + ';';
    }

    if (secure) {
      cookieString += 'secure;';
    }

    cookieString += 'sameSite=' + sameSite + ';';

    console.log('cookieString', cookieString);

    this.document.cookie = cookieString;
  }

  /**
   * @param name   Cookie name
   * @param path   Cookie path
   * @param domain Cookie domain
   */
  public delete(name: string, path?: string, domain?: string): void {
    if (!this.documentIsAccessible) {
      return;
    }

    this.set(name, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), path, domain);
  }

  /**
   * @param name Cookie name
   * @returns {RegExp}
   */
  private getCookieRegExp(name: string): RegExp {
    const escapedName: string = name.replace(
      // eslint-disable-next-line no-useless-escape
      /([$()*+,.;=?[\]^{|}])/gi,
      '\\$1'
    );

    return new RegExp(
      '(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)',
      'g'
    );
  }
}
