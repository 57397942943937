import { APP_ID, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';

import { environment } from '@environments/environment';
import { SentryUtil, SENTRY_IGNORE_ERRORS } from '@app/shared/utils/sentry.util';
import { enableAkitaProdMode, persistState, akitaConfig } from '@datorama/akita';

import localForage from 'localforage';
import { debounceTime } from 'rxjs';
import { UserTiming } from '@app/shared/utils/performance.utils';

// Sentry
import { init } from '@sentry/browser';
import { ViewAppComponent } from './app/core/view/app.view';
import { provideLottieOptions, provideCacheableAnimationLoader } from 'ngx-lottie';
import { APP_ROUTES } from './app/core/generated.routes';
import {
  withNavigationErrorHandler,
  withPreloading,
  withEnabledBlockingInitialNavigation,
  withRouterConfig,
  provideRouter,
} from '@angular/router';
import { LOADABLE_OPTIONS } from './app/core/overlays-to-lazy-load';
import { LoadableModule } from '@app/ngx-loadable/loadable.module';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { PopsyTranslocoMissingHandler } from './app/core/services/popsy-transloco-missing-handler.service';
import { PopsyTranslocoHttpLoader } from './app/core/services/popsy-transloco-http-loader.service';
import {
  TRANSLOCO_MISSING_HANDLER,
  TranslocoModule,
  provideTransloco,
} from '@ngneat/transloco';
import { DEVTOOLS_OPTIONS, AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { SentryErrorHandler } from './app/core/factories/sentry.plugin';
import { ErrorHttpInterceptor } from './app/core/interceptors/error-http.interceptor';
import { SessionRestoreInterceptor } from './app/core/interceptors/session-restore.interceptor';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { Title, bootstrapApplication } from '@angular/platform-browser';
import { PopsyPreloader } from './app/core/preloaders/popsy.preloader';
// import { Integrations as TracingIntegrations } from '@sentry/tracing';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function playerFactory() {
  return import(
    /* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_light'
  );
}

const DELAY_PERSIST_STATE = 2000;

const PERFORMANCE_MARK_NAME = 'Angular Bootstrap';
const PERFORMANCE_MARK_START = `${PERFORMANCE_MARK_NAME} - Start`;
const PERFORMANCE_MARK_END = `${PERFORMANCE_MARK_NAME} - End`;

const SENTRY_PERFORMANCE_MARK_NAME = 'Init Sentry';
const SENTRY_PERFORMANCE_MARK_START = `${SENTRY_PERFORMANCE_MARK_NAME} - Start`;
const SENTRY_PERFORMANCE_MARK_END = `${SENTRY_PERFORMANCE_MARK_NAME} - End`;

// const URL_PATHNAME_LANG_END = 3;

try {
  if (window && !(window as any).IS_SERVER && environment.reportToSentry) {
    UserTiming.createMark(SENTRY_PERFORMANCE_MARK_START);
    // Initialize Sentry
    init({
      maxBreadcrumbs: 80,
      dsn: environment.sentryUrl,
      // This enables automatic instrumentation (highly recommended), but is not
      // necessary for purely manual usage
      integrations: [
        // This causes CORS:
        // Ask API to add these 2 headers to all CORS Header Replies:
        // Access-Control-Allow-Headers: sentry-trace" and "Access-Control-Allow-Headers: baggage"
        // --> https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracingorigins
        //
        // new TracingIntegrations.BrowserTracing({
        //   tracingOrigins: [
        //     'localhost',
        //     'api.popsy.app',
        //     'api-staging.popsy.app',
        //     'api-dev.popsy.app',
        //     'api.popsy.com.br',
        //     'api-staging.popsy.com.br',
        //     'api-dev.popsy.com.br',
        //     /^\//,
        //   ],
        //   beforeNavigate: (context) => {
        //     let parsedPath = `${location.pathname || ''}`;
        //     parsedPath = parsedPath.replace(/[\da-f]{24}/g, '<id>');
        //     if (parsedPath.length === URL_PATHNAME_LANG_END) {
        //       parsedPath = '/';
        //     } else if (
        //       parsedPath.length >= URL_PATHNAME_LANG_END + 1 &&
        //       parsedPath[URL_PATHNAME_LANG_END] === '/'
        //     ) {
        //       parsedPath = parsedPath.slice(URL_PATHNAME_LANG_END);
        //     }
        //     if (parsedPath.indexOf('item')) {
        //       parsedPath = 'listing';
        //     }
        //     return {
        //       ...context,
        //       // You could use your UI's routing library to find the matching
        //       // route template here. We don't have one right now, so do some basic
        //       // parameter replacements.
        //       name: parsedPath,
        //     };
        //   },
        // }),
      ],
      // To set a uniform sample rate (performance)
      tracesSampleRate: 0.2,
      attachStacktrace: true,
      release: environment.app.version,
      environment: environment.name,
      enabled: environment.reportToSentry,
      debug:
        environment.name === 'dev' ||
        environment.name === 'ssr' ||
        environment.name === 'test',
      // ignoreErrors for type string or regex
      ignoreErrors: SENTRY_IGNORE_ERRORS,
      // filter errors on beforeSend
      beforeSend: (event: any): any | null | Promise<any | null> =>
        SentryUtil.filteredError(event),
      beforeBreadcrumb: (breadcrumb: any): any | null => breadcrumb,
    });

    UserTiming.createMark(SENTRY_PERFORMANCE_MARK_END);
    UserTiming.measure(
      SENTRY_PERFORMANCE_MARK_NAME,
      SENTRY_PERFORMANCE_MARK_START,
      SENTRY_PERFORMANCE_MARK_END
    );
  }
} catch (error) {
  // Accessing window will throw error when using in Server Side
}

UserTiming.createMark(PERFORMANCE_MARK_START);

akitaConfig({
  resettable: true,
});

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

try {
  localForage.config({
    name: 'Popsy',
    version: 1.0,
    storeName: 'state',
  });
} catch (error) {}

persistState({
  // storage: localForage,
  include: [
    'auth',
    'location',
    'screen',
    'categories',
    'firebase',
    'listings',
    'user-reviews',
    'users',
    'navigation',
    'delivery-onboarding',
  ],
  preStorageUpdateOperator: () => debounceTime(DELAY_PERSIST_STATE),
  preStoreUpdate: (storeName: string, state: any) => {
    let output = state;
    if (output) {
      if (storeName === 'location') {
        output = {
          ...output,
          currentLocation: null,
        };
      }
    }
    return output;
  },
});

function handleBootstrapError(err: unknown): void {
  if (!environment.production) {
    console.error(err);
  }
  SentryUtil.reportException(err, false);

  UserTiming.createMark(PERFORMANCE_MARK_END);
  UserTiming.measure(PERFORMANCE_MARK_NAME, PERFORMANCE_MARK_START, PERFORMANCE_MARK_END);
}

export const appComponentProviders = [
  importProvidersFrom(
    // PrebootModule.withConfig({ appRoot: 'app-root' }),

    // Lazy Load Components
    LoadableModule.forRoot(LOADABLE_OPTIONS),
    // Translations
    TranslocoModule,
    // Akita
    environment.production
      ? []
      : [
          AkitaNgDevtools.forRoot({
            name: `Popsy Frontend`,
            maxAge: 100,
            actionsBlacklist: [
              'GET_CONVERSATION',
              'GET_CONVERSATIONS_STARTE',
              'GET_CONVERSATIONS_SUCCES',
              'GET_CONVERSATION_MESSAGE',
              'GET_CONVERSATION_STARTED',
              'GET_CONVERSATION_SUCCESS',
            ],
            logTrace: true,
            shallow: true,
          }),
        ]
  ),

  PopsyPreloader,
  Title,
  { provide: APP_ID, useValue: 'popsy-frontend' },
  /* {
    provideClientHydration() {
      return new Promise<void>((resolve) => {
        document.addEventListener('hydrated', () => {
          resolve();
        });
      });
    },
    provide: HTTP_INTERCEPTORS,
    multi: true,
  }, */
  // Provide the Sentry Error Handler
  { provide: HTTP_INTERCEPTORS, useClass: SessionRestoreInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },
  {
    provide: ErrorHandler,
    useClass: environment.production ? SentryErrorHandler : ErrorHandler,
  },
  {
    provide: DEVTOOLS_OPTIONS,
    useValue: { shallow: false },
  },
  provideTransloco({
    config: {
      availableLangs: ['en', 'ar'],
      defaultLang: 'en',
      // Remove this option if your application doesn't support changing language in runtime.
      reRenderOnLangChange: true,
      prodMode: environment.production,
      fallbackLang: ['en', 'ar'],
      failedRetries: 1,
      missingHandler: {
        useFallbackTranslation: true,
        allowEmpty: true,
        logMissingKey: false,
      },
      flatten: {
        aot: environment.production,
      },
    },
    loader: PopsyTranslocoHttpLoader,
  }),
  {
    provide: TRANSLOCO_MISSING_HANDLER,
    useClass: PopsyTranslocoMissingHandler,
  },
  provideHttpClient(withInterceptorsFromDi()),
  provideNoopAnimations(),
  provideLottieOptions({
    player: playerFactory,
  }),
  provideCacheableAnimationLoader(),
  provideRouter(
    APP_ROUTES,
    withNavigationErrorHandler(console.error),
    withPreloading(PopsyPreloader),
    withEnabledBlockingInitialNavigation(),
    withRouterConfig({ paramsInheritanceStrategy: 'always' })
  ),
];

const bootstrap = () => {
  bootstrapApplication(ViewAppComponent, {
    providers: appComponentProviders,
  })
    .then(() => {
      UserTiming.createMark(PERFORMANCE_MARK_END);
      UserTiming.measure(
        PERFORMANCE_MARK_NAME,
        PERFORMANCE_MARK_START,
        PERFORMANCE_MARK_END
      );
    })
    .catch(handleBootstrapError);
};

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
