<header class="new-newtoolbar-wrapper" *transloco="let t">
  <div class="row first">
    <!--     Burguer Menu -->
    @if (!(biggerThan$ | async)?.mobileL) {
      <a
        class="popsy-icon flex"
        (click)="openBurguerMenu()"
        [attr.aria-label]="t('aria_action_home')">
        <popsy-icon-burguer-menu class="burguer-icon icon"></popsy-icon-burguer-menu>
      </a>
      <span class="dynamic-space first"></span>
    }

    <!-- Popsy Logo -->
    <ng-container>
      <a
        class="popsy-icon name flex"
        href=""
        [routerLink]="homeUrlFragment$ | async"
        [queryParams]="queryParamsToKeep$ | async"
        [attr.aria-label]="t('aria_action_home')">
        <popsy-icon-popsy-logo-new class="logo-icon icon" />
      </a>
    </ng-container>

    <span class="dynamic-space"></span>

    <!-- Search Box -->
    @if (!(isNoSearchView$ | async) && (biggerThan$ | async)?.mobileL) {
      <app-search-box
        [attr.aria-label]="t('hint_search')"
        class="search-box"
        searchType="search">
      </app-search-box>
      <span class="dynamic-space"></span>
    }

    <!-- Cart -->
    @if (((cartItemsCount$ | async) || 0) > 0 || (alwaysShowCart$ | async)) {
      <!-- Cart -->
      <button
        #cartBtn
        class="popsy-button-icon cart-button"
        [class.unread]="((cartItemsCount$ | async) || 0) > 0"
        [attr.data-before]="(cartItemsCount$ | async) || undefined"
        [attr.aria-label]="t('label_cart')"
        (click)="goToCart()">
        <popsy-icon-cart-logo class="icon" />
      </button>
    }

    <!-- User Avatar -->
    @if (user$ | async) {
      <a
        class="user-avatar"
        href=""
        [routerLink]="userUrlFragment"
        [queryParams]="queryParamsToKeep$ | async"
        [attr.aria-label]="t('action_see_profile')">
        <app-picture
          class="picture"
          [alt]="(user$ | async)?.firstName"
          [pictureUrl]="userAvatarUrl$ | async"
          [width]="'124px'"
          [height]="'124px'"></app-picture>
        @if (showDefaultAvatar) {
          <popsy-icon-single-user class="icon" />
        }
      </a>
    }

    <!-- Auth Buttons -->
    @if (!(user$ | async)) {
      <a
        #cartBtn
        class="popsy-button-icon sign-in"
        [class.unread]="true"
        [routerLink]="(isAuthView$ | async) ? signInUrlFragment : null"
        [queryParams]="
          (isAuthView$ | async)
            ? (signUpQueryParams$ | async)
            : (queryParamsToKeep$ | async)
        "
        (click)="openAuthDialog('auth-intro')">
        <popsy-icon-avatar class="icon" />
      </a>
    }
  </div>
  @if (!(isNoSearchView$ | async) && !(biggerThan$ | async)?.mobileL) {
    <div class="row second">
      <!-- Search Box -->
      <app-search-box
        [attr.aria-label]="t('hint_search')"
        class="full-width search-box"
        searchType="search">
      </app-search-box>
    </div>
  }
  @if (showActivity) {
    <div
      [attr.aria-busy]="showActivity"
      class="loading"
      role="progressbar"
      [attr.aria-valuetext]="t(activityTypeAriaLabel)">
      <div class="lead"></div>
    </div>
  }
</header>
