import { Params } from '@angular/router';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { PriceModel } from '@app/shared/models/api/price.model';
import { UserModel } from '@app/shared/models/api/user.model';

export interface NavigationInfo {
  currentView: PopsyViewName | null;
  currentScrollPosition: number | null;
  previousView: PopsyViewName | null;
  previousScrollPosition: number | null;
  isScrollingToPosition: boolean | null;
  isNavigatingBack: boolean | null;
}

export interface LanguageOption {
  display: string;
  country: string;
  locale: string;
  rtl?: boolean;
}

export interface LocationOption {
  display: string;
  country: string;
  domain?: string;
  flag?: string;
}

export type PopsyViewName =
  | 'ROOT'
  | 'INFO'
  | 'HOME'
  | 'PRODUCT'
  | 'COLLECTION'
  | 'ERROR'
  | 'LOGIN'
  | 'LISTING'
  | 'USER'
  | 'DELIVERY'
  | 'ORDER'
  | 'SESSION'
  | 'SEARCH-DELIVERY'
  | 'CATEGORY'
  | '';

export type PopsyViewStep =
  | 'RESET_PASSWORD_CHANGE'
  | 'CHAT'
  | 'SIGN_IN'
  | 'SIGN_UP'
  | 'RESET_PASSWORD'
  | 'NOTIFICATIONS'
  | 'PROFILE'
  | 'INFORMATION'
  | 'CHECKOUT_SESSION'

  // Checkout
  | 'CREATE'
  | 'CREATE_ADDRESS'
  | 'CREATE_OVERVIEW'
  | 'CREATE_PAYMENT'
  | 'CREATE_SUCCESS'
  | 'CREATE_PAYMENT_ERROR'
  | 'TABBY_SUCCESS'
  | 'TABBY_CANCEL'
  | 'TABBY_FAILURE'
  | 'CKO_SUCCESS'
  | 'CKO_FAIL'
  | '';

export interface AnalyticsMetadata {
  gclsrc: string | null;
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmContent: string | null;
  utmTerm: string | null;
  adgroup: string | null;
  network: string | null;
  device: string | null;
  merchantId: string | null;
  productIds: Array<string>;
  gclid: string | null;
  dclid: string | null;
  aclid: string | null;
  anid: string | null;
  cp1: string | null;
}

export interface RouteInformation {
  params: Params;
  queryParams: Params;
  data: Params;
  fragment: string;
}

export interface AkitaRouteFragments {
  homeUrlFragment: Array<string>;
}

export interface AkitaRouterState {
  queryParamsToKeep: Params;
  queryParams: Params;
  params: Params;
  data: Params;
  fragment: string;

  rtl: boolean;
  internalView: boolean;
  isOwnProfile: boolean;

  redirect: string;

  view: PopsyViewName;
  step: PopsyViewStep;

  navigation: NavigationInfo | null;

  routeFragments: AkitaRouteFragments;
  analyticsMetadata: AnalyticsMetadata;

  sessionUser: UserModel | null;
  lastUpdated: Date;

  userAgent: string | null;

  discountCode: string | null;
  discountCodeValues: {
    [code: string]: {
      [listingId: string]: PriceModel | null;
    };
  };
  validatingDiscountCode: boolean;
  errorValidatingDiscountCode: ApiError | null;
}

export const createInitialState = (): AkitaRouterState => ({
  queryParamsToKeep: {},
  queryParams: {},
  params: {},
  data: {},
  fragment: '',

  rtl: false,
  internalView: false,
  isOwnProfile: false,

  redirect: '',

  view: '',
  step: '',

  navigation: null,

  routeFragments: {
    homeUrlFragment: ['/'],
  },

  analyticsMetadata: {
    gclsrc: null,
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmContent: null,
    utmTerm: null,
    adgroup: null,
    network: null,
    device: null,
    merchantId: null,
    productIds: new Array(0),
    gclid: null,
    dclid: null,
    aclid: null,
    anid: null,
    cp1: null,
  },

  sessionUser: null,
  lastUpdated: new Date(),

  userAgent: null,

  discountCode: null,
  discountCodeValues: {},
  validatingDiscountCode: false,
  errorValidatingDiscountCode: null,
});
