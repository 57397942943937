export type AkitaOverlayType =
  | 'auth-overlay-module'
  | 'select-location-overlay'
  | 'price-filter-overlay'
  | 'filters-overlay'
  | 'currency-overlay'
  | 'delete-account-overlay'
  | 'edit-profile-overlay'
  | 'error-overlay'
  | 'confirm-overlay'
  | 'footer-overlay'
  | 'list-overlay'
  | 'report-overlay'
  | 'social-share-overlay'
  | 'open-on-mobile-app-overlay'
  | 'google-oauth2-overlay'
  | 'cart-overlay'
  | 'cart-promo-overlay'
  | 'shopify-cart-overlay'
  | 'feedback-overlay'
  | 'survey-overlay'
  | 'payment-options-overlay'
  | 'phone-country-overlay'
  | 'phone-filters-overlay'
  | 'newsletter-overlay'
  | 'burguer-menu-overlay'
  | 'conditions-info-overlay'
  | 'navigation-overlay'
  | 'insurance-overlay'
  | 'quara-overlay'
  | 'baseeta-overlay'
  | 'battery-overlay'
  | 'package-info-overlay'
  | 'retry-payment-overlay'
  | 'insurance-confirm-overlay'
  | 'video-overlay';

export interface AkitaOverlaysLoadStatus {
  'auth-overlay-module': boolean;
  'select-location-overlay': boolean;
  'price-filter-overlay': boolean;
  'filters-overlay': boolean;
  'currency-overlay': boolean;
  'delete-account-overlay': boolean;
  'edit-profile-overlay': boolean;
  'error-overlay': boolean;
  'confirm-overlay': boolean;
  'footer-overlay': boolean;
  'list-overlay': boolean;
  'report-overlay': boolean;
  'social-share-overlay': boolean;
  'open-on-mobile-app-overlay': boolean;
  'google-oauth2-overlay': boolean;
  'cart-overlay': boolean;
  'cart-promo-overlay': boolean;
  'shopify-cart-overlay': boolean;
  'feedback-overlay': boolean;
  'survey-overlay': boolean;
  'payment-options-overlay': boolean;
  'phone-country-overlay': boolean;
  'phone-filters-overlay': boolean;
  'newsletter-overlay': boolean;
  'burguer-menu-overlay': boolean;
  'conditions-info-overlay': boolean;
  'navigation-overlay': boolean;
  'insurance-overlay': boolean;
  'quara-overlay': boolean;
  'baseeta-overlay': boolean;
  'battery-overlay': boolean;
  'retry-payment-overlay': boolean;
  'package-info-overlay': boolean;
  'insurance-confirm-overlay': boolean;
  'video-overlay': boolean;
}

export interface AkitaOverlaysState {
  loaded: AkitaOverlaysLoadStatus;
  loading: AkitaOverlaysLoadStatus;
  error: AkitaOverlaysLoadStatus;
  preloadedAll: boolean;
  preloadStarted: boolean;
}

export const INITIAL_LOAD_STATUS: AkitaOverlaysLoadStatus = {
  'auth-overlay-module': false,
  'select-location-overlay': false,
  'price-filter-overlay': false,
  'filters-overlay': false,
  'currency-overlay': false,
  'delete-account-overlay': false,
  'edit-profile-overlay': false,
  'error-overlay': false,
  'confirm-overlay': false,
  'footer-overlay': false,
  'list-overlay': false,
  'report-overlay': false,
  'social-share-overlay': false,
  'open-on-mobile-app-overlay': false,
  'google-oauth2-overlay': false,
  'cart-overlay': false,
  'cart-promo-overlay': false,
  'shopify-cart-overlay': false,
  'feedback-overlay': false,
  'survey-overlay': false,
  'payment-options-overlay': false,
  'phone-country-overlay': false,
  'phone-filters-overlay': false,
  'newsletter-overlay': false,
  'burguer-menu-overlay': false,
  'conditions-info-overlay': false,
  'navigation-overlay': false,
  'insurance-overlay': false,
  'quara-overlay': false,
  'baseeta-overlay': false,
  'battery-overlay': false,
  'retry-payment-overlay': false,
  'package-info-overlay': false,
  'insurance-confirm-overlay': false,
  'video-overlay': false,
};
