<ng-container *transloco="let t">
  <section
    class="location-picker-bar-component popsy-negative"
    [class.hide]="hideBar() === true">
    <nav class="nav">
      <!-- Location -->
      <div class="menu" (click)="toggleLocationPicker()">
        <img
          class="flag"
          [src]="getCountryFlag((selectedLocation$ | async)?.country || 'SA')"
          alt="country" />
        <span class="fixed-space-8" aria-hidden="true"></span>

        <span #locationPicker class="small">
          {{ (selectedLocation$ | async)?.display || '' }}
        </span>
        <span class="fixed-space-8" aria-hidden="true"></span>

        <popsy-icon-arrow-down-1 class="icon gray-01"></popsy-icon-arrow-down-1>
      </div>

      <span class="fixed-space-16" aria-hidden="true"></span>

      <!-- Language -->
      <div class="menu" (click)="toggleLanguagePicker()">
        <span #languagePicker class="small">
          {{ (selectedLanguage$ | async)?.display || '' }}
        </span>
        <span class="fixed-space-8" aria-hidden="true"></span>

        <popsy-icon-arrow-down-1 class="icon gray-01"></popsy-icon-arrow-down-1>
      </div>
    </nav>
  </section>
  @if (biggerThan().mobileL === true && !showAnnouncementBar) {
    <section
      class="announce-bar"
      [class.rtl]="rtl() === true"
      (click)="openNewsletterDialog()">
      <div class="container">
        {{ announcementBarText()?.[(selectedLanguage$ | async)?.locale || 'en'] || '' }}
      </div>

      <button class="button popsy-button-primary highlight pill">
        {{ t('action_join') }}
      </button>
    </section>
  }
</ng-container>
