import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  AkitaRemoteConfigStore,
  AkitaRemoteConfigState,
} from '@app/akita/api/remote-config/state/remote-config.store';
import { Observable, of, distinctUntilChanged, map } from 'rxjs';
import {
  DEFAULT_CARD_PAYMENT_PROVIDER,
  CardPaymentProviders,
} from '../../payments/models/payment-providers.model';
import { BannerInfo, parseBannerInfoFromJsonArray } from '../models/banner-info.model';
import {
  CardOnlyDiscountCodes,
  parseCardOnlyDiscountCodesFromJsonArray,
} from '../models/card-only-discount-codes.model';
import { ExperimentCashOnDeliveryMode } from '../models/experiment-cod.enum';
import {
  FeaturedBanner,
  parseFeaturedBannerFromJson,
} from '../models/featured-banner.model';
import {
  DeliveryEstimate,
  EstimateDays,
  parseDeliveryEstimateFromJsonArray,
} from '../models/delivery-estimate.model';
import { SharedImageCategory } from '../models/shared-category-images.model';
import { NewsletterSubtitle } from '../models/newsletter-subtitle.model';
import { AkitaProductsQuery } from '../../products/state/products.query';
import { PopularDevice, PopularDevices } from '../models/popular-devices.model';
import { FeatureBestSellers } from '../models/feature-best-sellers';
import { BestSellersPerCategory } from '../models/best-sellers-per-category.model';
import { CarouselBannerSlide } from '../models/carousel-banner-slide.model';

const DEFAULT_DELIVERY_MIN_DAYS = 5;
const DEFAULT_DELIVERY_MAX_DAYS = 7;

const TEST_PRODUCT_PAGE_VALUES = ['original', 'ppVar1'];

@Injectable({ providedIn: 'root' })
export class AkitaRemoteConfigQuery extends Query<AkitaRemoteConfigState> {
  constructor(
    protected store: AkitaRemoteConfigStore,
    private readonly akitaProductsQuery: AkitaProductsQuery
  ) {
    super(store);
  }

  /**
   * @deprecated Loggi V1
   */
  public get isFeatureDeliveryEnabled(): boolean {
    return this.getValue().featureDeliveryEnabled;
  }

  public get sharedImageCategory(): SharedImageCategory | null {
    console.log(
      'this.getValue().sharedImageCategory',
      this.getValue().sharedImageCategory
    );
    return this.getValue().sharedImageCategory;
  }

  public selectSharedImageCategory(): Observable<SharedImageCategory | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.sharedImageCategory),
      distinctUntilChanged()
    );
  }

  public get newsletterSubtitle(): NewsletterSubtitle | null {
    return this.getValue().newsletterSubtitle;
  }

  public getNewsletterSubtitle(): string | null {
    const currentLang = this.akitaProductsQuery.language || 'en';
    let subtitle = null;
    const stateValue = this.getValue().newsletterSubtitle;
    if (stateValue && stateValue[currentLang]) {
      subtitle = stateValue[currentLang] || null;
    }

    return subtitle;
  }

  public selectAnnouncementBarText(): Observable<NewsletterSubtitle | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.announcementBarText),
      distinctUntilChanged()
    );
  }

  public get allowCashOnDelivery(): boolean {
    return Boolean(this.getValue().featureCashOnDelivery);
  }

  public get experimentCashOnDeliveryMode(): ExperimentCashOnDeliveryMode | null {
    return this.getValue().experimentCashOnDelivery || null;
  }

  public get sendWebReviewsTo(): string | null {
    return this.getValue().sendWebReviewsTo || null;
  }

  public get experimentCheckout(): string | null {
    return this.getValue().experimentCheckout || null;
  }

  public get leaveDeliverySurveyQuestion(): string {
    return `${
      this.getValue().leaveDeliverySurveyQuestion || 'leave_cart_survey_question_1'
    }`;
  }

  public get banners(): Array<BannerInfo> {
    return parseBannerInfoFromJsonArray(this.getValue().featureFeatured?.banners);
  }

  public get banner(): FeaturedBanner | null {
    return parseFeaturedBannerFromJson(this.getValue().featuredBanner);
  }

  public get paymentProvider(): CardPaymentProviders {
    return this.getValue().paymentProvider || DEFAULT_CARD_PAYMENT_PROVIDER;
  }

  public get checkoutFlow(): string | null {
    return this.getValue().checkoutFlow || 'DELIVERY_FLOW';
  }

  public getCardOnlyDiscountCodeForCountry(
    country?: string | null
  ): CardOnlyDiscountCodes | null {
    const list = parseCardOnlyDiscountCodesFromJsonArray(
      this.getValue().cardOnlyDiscountCodes
    );
    let discount: CardOnlyDiscountCodes | null = null;
    if (country) {
      const parsedCountry = `${country || ''}`.toUpperCase();
      for (const item of list) {
        if (item.country === parsedCountry) {
          discount = item;
          break;
        }
      }
    }
    return discount;
  }

  public getDeliveryEstimateForCountry(country?: string | null): EstimateDays {
    const list = parseDeliveryEstimateFromJsonArray(this.getValue().deliveryEstimates);
    let estimate: EstimateDays = new EstimateDays(
      DEFAULT_DELIVERY_MIN_DAYS,
      DEFAULT_DELIVERY_MAX_DAYS
    );
    if (country) {
      const parsedCountry = `${country || ''}`.toUpperCase();
      for (const item of list) {
        if (item.country === parsedCountry) {
          estimate = item.values;
          break;
        }
      }
    }
    return estimate;
  }

  // Async

  public selectIsFeatureDeliveryEnabledLoggi(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.featureDeliveryEnabledLoggi),
      distinctUntilChanged()
    );
  }

  public selectAllowCashOnDelivery(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.featureCashOnDelivery),
      distinctUntilChanged()
    );
  }

  public selectBanners(): Observable<Array<BannerInfo>> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) =>
        parseBannerInfoFromJsonArray(state.featureFeatured?.banners)
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectBanner(): Observable<FeaturedBanner | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) =>
        parseFeaturedBannerFromJson(state.featuredBanner)
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectFeatureFeaturedSellerId(): Observable<string> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => `${state.featureFeatured?.sellerId || ''}`),
      distinctUntilChanged()
    );
  }

  public selectCurrentConditionSet(): Observable<string> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => `${state.currentConditionSet || ''}`),
      distinctUntilChanged()
    );
  }

  public selectPaymentProvider(): Observable<CardPaymentProviders> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => {
        const stateProvider = state.paymentProvider;
        let provider: CardPaymentProviders = DEFAULT_CARD_PAYMENT_PROVIDER;
        if (stateProvider === 'STRIPE_V2') {
          provider = 'STRIPE_V2';
        } else if (stateProvider === 'CHECKOUT') {
          provider = 'CHECKOUT';
        }
        return provider;
      }),
      distinctUntilChanged()
    );
  }

  public selectPopularDevices(): Observable<PopularDevices | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.popularDevices),
      distinctUntilChanged()
    );
  }

  public selectBestSellersPerCategory(): Observable<BestSellersPerCategory | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.bestSellersPerCategory),
      distinctUntilChanged()
    );
  }

  public selectBestProductsTamaraTest(): Observable<BestSellersPerCategory | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.bestProductsTamaraTest),
      distinctUntilChanged()
    );
  }

  public selectPopularDevice(
    category: string | null,
    brand: string | null
  ): Observable<PopularDevice | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => {
        const popularDevices = state.popularDevices;
        if (
          popularDevices &&
          category &&
          brand &&
          popularDevices[category] &&
          popularDevices[category][brand]
        ) {
          return popularDevices[category][brand];
        }
        return null;
      }),
      distinctUntilChanged()
    );
  }

  public selectCarouselBannerSlides(): Observable<Array<CarouselBannerSlide> | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.carouselBannerSlides),
      distinctUntilChanged()
    );
  }

  public selectFeatureBestSellers(): Observable<FeatureBestSellers | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.featureBestSellers),
      distinctUntilChanged()
    );
  }

  public selectTestProductPage(): Observable<string> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) =>
        TEST_PRODUCT_PAGE_VALUES.includes(state.testProductPage)
          ? state.testProductPage
          : 'original'
      ),
      distinctUntilChanged()
    );
  }

  public selectCheckoutFlow(): Observable<string> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => {
        const stateFlow = state.checkoutFlow;
        let flow = 'DELIVERY_FLOW';
        if (stateFlow === 'DELIVERY_FLOW') {
          flow = 'DELIVERY_FLOW';
        } else if (stateFlow === 'CHECKOUT_SESSION') {
          flow = 'CHECKOUT_SESSION';
        } else if (stateFlow === 'CHECKOUT_SESSION_NOEXPRESS') {
          flow = 'CHECKOUT_SESSION_NOEXPRESS';
        }
        return flow;
      }),
      distinctUntilChanged()
    );
  }

  public selectAddressSearch(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.addressSearch),
      distinctUntilChanged()
    );
  }

  public selectExpressCheckout(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.expressCheckout),
      distinctUntilChanged()
    );
  }

  public selectExperimentCheckout(): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.experimentCheckout),
      distinctUntilChanged()
    );
  }

  public selectTamaraPayNowExperiment(): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.tamaraPayNowExperiment),
      distinctUntilChanged()
    );
  }

  public selectIsLoading(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.isLoading),
      distinctUntilChanged()
    );
  }

  public selectExperimentCashOnDelivery(): Observable<ExperimentCashOnDeliveryMode | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.experimentCashOnDelivery || null),
      distinctUntilChanged()
    );
  }

  public selectCardOnlyDiscountCodeForCountry(
    country?: string | null
  ): Observable<CardOnlyDiscountCodes | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => {
        const list = parseCardOnlyDiscountCodesFromJsonArray(state.cardOnlyDiscountCodes);
        let discount: CardOnlyDiscountCodes | null = null;
        if (country) {
          const parsedCountry = `${country || ''}`.toUpperCase();
          for (const item of list) {
            if (item.country === parsedCountry) {
              discount = item;
              break;
            }
          }
        }

        return discount;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectDeliveryEstimateForCountry(
    country?: string | null
  ): Observable<EstimateDays> {
    const defaultEstimate = new EstimateDays(
      DEFAULT_DELIVERY_MIN_DAYS,
      DEFAULT_DELIVERY_MAX_DAYS
    );
    return (
      this.select().pipe(
        map((state: AkitaRemoteConfigState) => {
          const list = state.deliveryEstimates;
          if (country) {
            const parsedCountry = `${country || ''}`.toUpperCase();
            let estimate = list.find((x) => x.country === parsedCountry);
            if (estimate) {
              return estimate.values;
            } else {
              estimate = list.find((x) => x.country === 'SA');
              return estimate?.values || defaultEstimate;
            }
          }
          return defaultEstimate;
        }),
        distinctUntilChanged()
      ) || of(defaultEstimate)
    );
  }

  public selectDeliveryEstimate(): Observable<Array<DeliveryEstimate | null>> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.deliveryEstimates || null),
      distinctUntilChanged()
    );
  }
}
