/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { LanguageGuard } from './guards/language.guard';
import { AuthGuard } from './guards/auth.guard';
import { RedirectGuard } from './guards/redirect.guard';
import { Routes } from '@angular/router';
import { UserProfileResolver } from '@app/user-profile/resolvers/user-profile.resolver';

// migrate to standalone components
const userProfileModuleLoader = () =>
  import(
    /* webpackChunkName: "user-profile" */ '../user-profile/view/user-profile.view'
  ).then((mod) => mod.UserProfileComponent);

const featuredPageModuleLoader = () =>
  import(
    /* webpackChunkName: "products-search" */ '../products/search/view/product-search.view'
  ).then((mod) => mod.ProductSearchViewComponent);

const homePageModuleLoader = () =>
  import(/* webpackChunkName: "home" */ '../home-new/view/home.view.component').then(
    (mod) => mod.HomeViewComponent
  );

export const MAIN_APP_ROUTES: Routes = [
  // Android App Page (Redirect to Play Store)
  {
    path: 'android',
    loadComponent: homePageModuleLoader,
    data: {
      view: 'INFO',
      step: 'ANDROID_LINK',
      fragment: ['android'],
      analyticsName: 'download_link_android',
    },
    canActivate: [RedirectGuard],
  },
  // iOS App Page (Redirect to App Store)
  {
    path: 'ios',
    loadComponent: homePageModuleLoader,
    data: {
      view: 'INFO',
      step: 'IOS_LINK',
      fragment: ['ios'],
      analyticsName: 'download_link_ios',
    },
    canActivate: [RedirectGuard],
  },

  // Privacy Policy Page (EN)
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ '../privacy-policy/privacy-policy.view'
      ).then((module) => module.PrivacyPolicyComponent),
    data: {
      view: 'INFO',
      step: 'PRIVADY_POLICY',
      fragment: ['privacy-policy'],
      analyticsName: 'privacy_policy',
    },
    canActivate: [LanguageGuard, AuthGuard],
  },

  // Terms & Conditions Page (EN)
  {
    path: 'terms-conditions',
    loadComponent: () =>
      import(
        /* webpackChunkName: "terms-conditions" */ '../terms-conditions/terms-conditions.view'
      ).then((module) => module.TermsConditionsComponent),
    data: {
      view: 'INFO',
      step: 'TERMS_CONDITIONS',
      fragment: ['terms-conditions'],
      analyticsName: 'terms_and_conditions',
    },
    canActivate: [LanguageGuard, AuthGuard],
  },

  // Refund Policy Page (EN)
  {
    path: 'refund-policy',
    loadComponent: () =>
      import(
        /* webpackChunkName: "refund-policy" */ '../refund-policy/refund-policy.view'
      ).then((module) => module.RefundPolicyComponent),
    data: {
      view: 'INFO',
      step: 'REFUND_POLICY',
      fragment: ['refund-policy'],
      analyticsName: 'refund_policy',
    },
    canActivate: [LanguageGuard],
  },

  // Terms of Use Page (EN)
  {
    path: 'terms-of-use',
    loadComponent: () =>
      import(
        /* webpackChunkName: "terms-of-use" */ '../terms-of-use/terms-of-use.view'
      ).then((module) => module.TermsOfUseComponent),
    data: {
      view: 'INFO',
      step: 'TERMS_OF_USE',
      fragment: ['terms-of-use'],
      analyticsName: 'terms_of_use',
    },
    canActivate: [LanguageGuard],
  },
  {
    path: 'warranty-and-refunds',
    loadComponent: () =>
      import(
        /* webpackChunkName: "warranty-and-refunds" */ '../warranty-and-refunds/warranty-and-refunds.view'
      ).then((module) => module.WarrantyAndRefundsComponent),
    data: {
      view: 'INFO',
      step: 'WARRANTY_AND_REFUNDS',
      fragment: ['warranty-and-refunds'],
      analyticsName: 'warranty-and-refunds',
    },
    canActivate: [LanguageGuard],
  },
  {
    path: 'payment-methods',
    loadComponent: () =>
      import(
        /* webpackChunkName: "payment-methods" */ '../payment-methods/payment-methods.view'
      ).then((module) => module.PaymentMethodsComponent),
    data: {
      view: 'INFO',
      step: 'PAYMENT_METHODS',
      fragment: ['payment-methods'],
      analyticsName: 'payment-methods',
    },
    canActivate: [LanguageGuard],
  },

  {
    path: 'installment-plans',
    loadComponent: () =>
      import(
        /* webpackChunkName: "installment-plans" */ '../installment-plans/installment-plans.view'
      ).then((module) => module.InstallmentPlansComponent),
    data: {
      view: 'INFO',
      step: 'INSTALLMENT_PLANS',
      fragment: ['installment-plans'],
      analyticsName: 'installment-plans',
    },
  },

  {
    path: 'faq',
    loadComponent: () =>
      import(/* webpackChunkName: "faq" */ '../faq-page/faq-page.view').then(
        (module) => module.FaqPageComponent
      ),
    data: {
      view: 'INFO',
      step: 'FAQ',
      fragment: ['faq'],
      analyticsName: 'faq',
    },
  },

  {
    path: 'shipping-and-delivery',
    loadComponent: () =>
      import(
        /* webpackChunkName: "shipping-and-delivery" */ '../shipping-and-delivery/shipping-and-delivery.view'
      ).then((module) => module.ShippingAndDeliveryComponent),
    data: {
      view: 'INFO',
      step: 'SHIPPING_AND_DELIVERY',
      fragment: ['shipping-and-delivery'],
      analyticsName: 'shipping-and-delivery',
    },
  },

  // Terms of Use Page (EN)
  {
    path: 'credit-card-installments',
    loadComponent: () =>
      import(
        /* webpackChunkName: "installment-plans" */ '../credit-installment-plans/credit-installment-plans.view'
      ).then((module) => module.CreditInstallmentPlansComponent),
    data: {
      view: 'INFO',
      step: 'CREDIT-INSTALLMENT_PLANS',
      fragment: ['credit-card-installments'],
      analyticsName: 'credit-card-installments',
    },
    canActivate: [LanguageGuard],
  },
  // Insurance Terms of Use Page (EN)
  {
    path: 'popsy-kafala-terms',
    loadComponent: () =>
      import(
        /* webpackChunkName: "popsy-kafala-terms" */ '../popsy-kafala-terms/popsy-kafala-terms.view'
      ).then((module) => module.PopsyKafalaTermsComponent),
    data: {
      view: 'INFO',
      step: 'TERMS_OF_USE',
      fragment: ['popsy-kafala-terms'],
      analyticsName: 'popsy__kafala_terms',
    },
    canActivate: [LanguageGuard],
  },
  // About Us Page
  {
    path: 'about-us',
    loadComponent: () =>
      import(/* webpackChunkName: "about-us" */ '../about-us/about-us.view').then(
        (module) => module.AboutUsComponent
      ),
    data: {
      view: 'INFO',
      step: 'ABOUT_US',
      fragment: ['about-us'],
      analyticsName: 'about_us_popsy',
    },
    canActivate: [LanguageGuard],
  },
  // Cart Page
  {
    path: 'cart',
    loadComponent: () =>
      import(/* webpackChunkName: "cart" */ '../cart/view/cart.view.component').then(
        (module) => module.CartViewComponent
      ),
    data: {
      view: 'INFO',
      step: 'CART',
      fragment: ['cart'],
      analyticsName: 'cart',
    },
    canActivate: [LanguageGuard],
  },
  // Suppliers Landing Page
  {
    path: 'seller',
    loadComponent: () =>
      import(
        /* webpackChunkName: "seller" */ '../suppliers-page/suppliers-page.view'
      ).then((module) => module.SuppliersPageComponent),
    data: {
      view: 'INFO',
      step: 'Seller',
      fragment: ['seller'],
      analyticsName: 'seller_popsy',
    },
    canActivate: [LanguageGuard],
  },
  // Customer Reviews Page
  {
    path: 'customer-reviews',
    loadComponent: () =>
      import(
        /* webpackChunkName: "customer-reviews" */ '../customer-reviews/customer-reviews.view'
      ).then((module) => module.CustomerReviewsComponent),
    data: {
      view: 'INFO',
      step: 'CUSTOMER_REVIEWS',
      fragment: ['customer-reviews'],
      analyticsName: 'customer_reviews_popsy',
    },
    canActivate: [LanguageGuard],
  },

  // Customer Reviews Page
  {
    path: 'baseeta',
    loadComponent: () =>
      import(
        /* webpackChunkName: "baseeta-info" */ '../baseeta-info/baseeta-info.view'
      ).then((module) => module.BaseetaInfoComponent),
    data: {
      view: 'INFO',
      step: 'CUSTOMER_REVIEWS',
      fragment: ['baseeta'],
      analyticsName: 'baseeta',
    },
    canActivate: [LanguageGuard],
  },

  // Customer Reviews Page
  {
    path: 'order-review',
    loadComponent: () =>
      import(
        /* webpackChunkName: "order-review" */ '../order-review/order-review.view'
      ).then((module) => module.OrderReviewComponent),
    data: {
      view: 'INFO',
      step: 'ORDER_REVIEW',
      fragment: ['order-review'],
      analyticsName: 'order-review_popsy',
    },
    canActivate: [LanguageGuard],
  },

  // Not Found Page
  {
    path: 'not-found',
    loadComponent: () =>
      import(/* webpackChunkName: "not-found" */ '../not-found/not-found.view').then(
        (module) => module.NotFoundComponent
      ),
    data: {
      view: 'ERROR',
      step: 'NOT_FOUND',
      fragment: ['not-found'],
      analyticsName: 'not_found',
      preload: false,
    },
    canActivate: [LanguageGuard, AuthGuard],
  },
  // Error Page
  {
    path: 'error',
    loadComponent: () =>
      import(/* webpackChunkName: "error" */ '../error/error.view').then(
        (module) => module.UnknownErrorComponent
      ),
    data: {
      view: 'ERROR',
      step: 'ERROR',
      fragment: ['error'],
      analyticsName: 'error',
      preload: false,
    },
    canActivate: [LanguageGuard, AuthGuard],
  },
  // Offline Page
  {
    path: 'offline',
    loadComponent: () =>
      import(/* webpackChunkName: "offline" */ '../offline/offline.view').then(
        (module) => module.OfflineComponent
      ),
    data: {
      view: 'ERROR',
      step: 'OFFLINE',
      fragment: ['offline'],
      analyticsName: 'offline',
      preload: false,
    },
    canActivate: [LanguageGuard, AuthGuard],
  },
  // Login Page (Reset Password & Callback after login with token)
  {
    path: 'login',
    data: { view: 'LOGIN', step: 'SUCCESS', fragment: ['login'] },
    canActivate: [LanguageGuard, AuthGuard],
    children: [
      {
        path: 'reset-password',
        loadComponent: () =>
          import(
            /* webpackChunkName: "login" */ '../reset-password-change-view/reset-password-change-view.view'
          ).then((module) => module.ResetPasswordChangeViewComponent),
        data: {
          internal: true,
          view: 'LOGIN',
          step: 'RESET_PASSWORD_CHANGE',
          fragment: ['login', 'reset-password'],
          analyticsName: 'reset_password_confirm',
        },
      },
      {
        path: '',
        loadComponent: homePageModuleLoader,
        data: {
          view: 'LOGIN',
          step: 'SUCCESS',
          navigation: true,
          protected: true,
          analyticsName: 'login_success',
        },
      },
    ],
  },
  // Logout Page
  {
    path: 'logout',
    data: {
      view: 'LOGOUT',
      step: 'LOGOUT',
      fragment: ['logout'],
      analyticsName: 'logout',
      protected: true,
    },
    canActivate: [LanguageGuard, AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import(/* webpackChunkName: "logout" */ '../logout/logout.view').then(
            (module) => module.LogoutComponent
          ),
      },
    ],
  },
  // Settings Page
  {
    path: 'settings',
    canActivate: [LanguageGuard, AuthGuard],
    loadComponent: () =>
      import(/* webpackChunkName: "settings" */ '../settings/view/settings.view').then(
        (module) => module.SettingsComponent
      ),
    resolve: {},
    data: {
      view: 'SETTINGS',
      step: 'SETTINGS',
      fragment: ['settings'],
      analyticsName: 'settings',
      protected: true,
    },
    children: [
      {
        path: '',
        /* component: SettingsComponent, */
        children: [
          {
            path: 'user',
            loadComponent: () =>
              import(
                /* webpackChunkName: "user-settings" */ '../settings/inner-views/user/user-settings.view'
              ).then((module) => module.UserSettingsComponent),
            data: {
              view: 'SETTINGS',
              step: 'USER',
              fragment: ['settings', 'user'],
              analyticsName: 'user-settings',
              protected: true,
            },
          },
          {
            path: 'address',
            loadComponent: () =>
              import(
                /* webpackChunkName: "address-settings" */ '../settings/inner-views/address/address-settings.view'
              ).then((module) => module.AddressSettingsComponent),
            data: {
              view: 'SETTINGS',
              step: 'ADDRESS',
              fragment: ['settings', 'address'],
              analyticsName: 'address-settings',
              protected: true,
            },
          },
          { path: '', redirectTo: 'user', pathMatch: 'full' },
        ],
      },
    ],
  },
  // User Pages
  {
    path: 'user',
    data: { view: 'USER', step: 'USER', fragment: ['user'], analyticsName: 'user' },
    canActivate: [LanguageGuard, AuthGuard],
    resolve: {},
    children: [
      // Sign In Page
      {
        path: 'sign-in',
        loadComponent: () =>
          import(
            /* webpackChunkName: "sign-in-view" */ '../sign-in-view/sign-in-view.view'
          ).then((module) => module.SignInViewComponent),
        data: {
          view: 'USER',
          step: 'SIGN_IN',
          analyticsName: 'sign_in',
          fragment: ['user', 'sign-in'],
        },
      },
      // Sign Up Page
      {
        path: 'sign-up',
        loadComponent: () =>
          import(
            /* webpackChunkName: "sign-up-view" */ '../sign-up-view/sign-up-view.view'
          ).then((module) => module.SignUpViewComponent),
        data: {
          view: 'USER',
          step: 'SIGN_UP',
          analyticsName: 'sign_up',
          fragment: ['user', 'sign-up'],
        },
      },
      // Reset Password Page
      {
        path: 'reset-password',
        loadComponent: () =>
          import(
            /* webpackChunkName: "reset-password" */ '../reset-password-view/reset-password-view.view'
          ).then((module) => module.ResetPasswordViewComponent),
        data: {
          view: 'USER',
          step: 'RESET_PASSWORD',
          analyticsName: 'reset_password_request',
          fragment: ['user', 'reset-password'],
        },
      },

      // Other Users Profile Page
      {
        path: ':id',
        data: {
          view: 'USER',
          step: 'PROFILE',
          fragment: ['user', ':id'],
          analyticsName: 'user',
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          userId: UserProfileResolver,
        },
        children: [
          {
            path: ':username',
            loadComponent: userProfileModuleLoader,
            data: {
              view: 'USER',
              step: 'PROFILE',
              fragment: ['user', ':id', ':username'],
              analyticsName: 'user',
            },
          },
          {
            path: '',
            loadComponent: userProfileModuleLoader,
            data: {
              view: 'USER',
              step: 'PROFILE',
              fragment: ['user', ':id'],
              analyticsName: 'user',
            },
          },
        ],
      },
      // My User Profile Page
      {
        path: '',
        loadComponent: userProfileModuleLoader,
        data: {
          view: 'USER',
          step: 'PROFILE',
          fragment: ['user'],
          analyticsName: 'user',
          protected: true,
        },
      },
    ],
  },

  // Checkout Session Page
  {
    path: 'checkout',
    canActivate: [LanguageGuard, AuthGuard],
    data: {
      view: 'CHECKOUT',
      step: 'CHECKOUT_ROOT',
      navigation: true,
      analyticsName: 'checkout',
      fragment: ['checkout'],
    },
    resolve: {},
    children: [
      {
        path: ':pid',
        data: {
          view: 'SESSION',
          step: 'CHECKOUT_SESSION',
          navigation: true,
          analyticsName: 'CheckoutSession',
          fragment: ['checkout', ':pid'],
        },
        // resolve: {},

        children: [
          // Create Delivery Overview Page
          {
            path: 'overview',
            loadComponent: () =>
              import(
                /* webpackChunkName: "checkout-session" */ '../checkout/views/checkout-session/checkout-session.view'
              ).then((module) => module.CheckoutSessionComponent),
            data: {
              view: 'SESSION',
              step: 'CHECKOUT_SESSION',
              navigation: true,
              analyticsName: 'CheckoutSession',
              fragment: ['delivery', ':pid', 'overview'],
            },
          },

          // Create checkout Payment Checkout.com Success
          {
            path: 'cko-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "cko-callback" */ '../checkout/views/cko-callback/cko-callback.view'
              ).then((module) => module.CheckoutDotComCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'CKO_SUCCESS',
              analyticsName: 'checkout_create_cko_success',
              fragment: ['checkout', ':pid', 'cko-success'],
            },
          },
          // Create checkout Payment Checkout.com error
          {
            path: 'cko-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "cko-callback" */ '../checkout/views/cko-callback/cko-callback.view'
              ).then((module) => module.CheckoutDotComCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'CKO_FAIL',
              analyticsName: 'checkout_create_cko_fail',
              fragment: ['checkout', ':pid', 'cko-failure'],
            },
          },
          // Create checkout Payment Tabby Success
          {
            path: 'tabby-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tabby-callback" */ '../checkout/views/tabby-callback/tabby-callback.view'
              ).then((module) => module.TabbyCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TABBY_SUCCESS',
              analyticsName: 'checkout_create_tabby_success',
              fragment: ['checkout', ':pid', 'tabby-success'],
            },
          },
          // Create checkout Payment Tabby Cancel
          {
            path: 'tabby-cancel',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tabby-callback" */ '../checkout/views/tabby-callback/tabby-callback.view'
              ).then((module) => module.TabbyCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TABBY_CANCEL',
              analyticsName: 'checkout_create_tabby_cancel',
              fragment: ['checkout', ':pid', 'tabby-cancel'],
            },
          },
          // Create checkout Payment Tabby Failure
          {
            path: 'tabby-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tabby-callback" */ '../checkout/views/tabby-callback/tabby-callback.view'
              ).then((module) => module.TabbyCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TABBY_FAILURE',
              analyticsName: 'checkout_create_tabby_failure',
              fragment: ['checkout', ':pid', 'tabby-failure'],
            },
          },

          // Create checkout Payment Quara Success
          {
            path: 'quara-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "quara-callback" */ '../checkout/views/quara-callback/quara-callback.view'
              ).then((module) => module.QuaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'QUARA_SUCCESS',
              analyticsName: 'checkout_create_quara_success',
              fragment: ['checkout', ':pid', 'quara-success'],
            },
          },
          // Create checkout Payment Quara Cancel
          {
            path: 'quara-cancel',
            loadComponent: () =>
              import(
                /* webpackChunkName: "quara-callback" */ '../checkout/views/quara-callback/quara-callback.view'
              ).then((module) => module.QuaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'QUARA_CANCEL',
              analyticsName: 'checkout_create_quara_cancel',
              fragment: ['checkout', ':pid', 'quara-cancel'],
            },
          },
          // Create checkout Payment Quara Failure
          {
            path: 'quara-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "quara-callback" */ '../checkout/views/quara-callback/quara-callback.view'
              ).then((module) => module.QuaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'QUARA_FAILURE',
              analyticsName: 'checkout_create_quara_failure',
              fragment: ['checkout', ':pid', 'quara-failure'],
            },
          },

          // Create checkout Payment Quara Success
          {
            path: 'baseeta-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "baseeta-callback" */ '../checkout/views/baseeta-callback/baseeta-callback.view'
              ).then((module) => module.BaseetaCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'BASEETA_SUCCESS',
              analyticsName: 'checkout_create_baseeta_success',
              fragment: ['checkout', ':pid', 'baseeta-success'],
            },
          },
          // Create checkout Payment Quara Failure
          {
            path: 'baseeta-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "baseeta-callback" */ '../checkout/views/baseeta-callback/baseeta-callback.view'
              ).then((module) => module.BaseetaCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'BASEETA_FAILURE',
              analyticsName: 'checkout_create_baseeta_failure',
              fragment: ['checkout', ':pid', 'baseeta-failure'],
            },
          },

          // Create checkout Payment Quara Success
          {
            path: 'stc-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "stc-callback" */ '../checkout/views/stc-callback/stc-callback.view'
              ).then((module) => module.StcPayCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'STC_PAY_SUCCESS',
              analyticsName: 'checkout_create_stc_pay_success',
              fragment: ['checkout', ':pid', 'stc-success'],
            },
          },
          // Create checkout Payment Quara Failure
          {
            path: 'stc-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "stc-callback" */ '../checkout/views/stc-callback/stc-callback.view'
              ).then((module) => module.StcPayCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'STC_PAY_FAILURE',
              analyticsName: 'checkout_create_stc_pay_failure',
              fragment: ['checkout', ':pid', 'stc-failure'],
            },
          },

          // Create checkout Payment Paypal Success
          {
            path: 'paypal-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "paypal-callback" */ '../checkout/views/paypal-callback/paypal-callback.view'
              ).then((module) => module.PaypalCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'PAYPAL_SUCCESS',
              analyticsName: 'checkout_create_paypal_success',
              fragment: ['checkout', ':pid', 'paypal-success'],
            },
          },
          // Create checkout Payment PayPal Cancel
          {
            path: 'paypal-cancel',
            loadComponent: () =>
              import(
                /* webpackChunkName: "paypal-callback" */ '../checkout/views/paypal-callback/paypal-callback.view'
              ).then((module) => module.PaypalCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'PAYPAL_CANCEL',
              analyticsName: 'checkout_create_paypal_cancel',
              fragment: ['checkout', ':pid', 'paypal-cancel'],
            },
          },
          // Create checkout Payment PayPal Failure
          {
            path: 'paypal-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "paypal-callback" */ '../checkout/views/paypal-callback/paypal-callback.view'
              ).then((module) => module.PaypalCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'PAYPAL_FAILURE',
              analyticsName: 'checkout_create_paypal_failure',
              fragment: ['checkout', ':pid', 'paypal-failure'],
            },
          },

          // Createcheckout Payment Tamara Success
          {
            path: 'tamara-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tamara-callback" */ '../checkout/views/tamara-callback/tamara-callback.view'
              ).then((module) => module.TamaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TAMARA_SUCCESS',
              analyticsName: 'checkout_create_tamara_success',
              fragment: ['checkout', ':pid', 'tamara-success'],
            },
          },
          // Create checkout Payment Tamara Cancel
          {
            path: 'tamara-cancel',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tamara-callback" */ '../checkout/views/tamara-callback/tamara-callback.view'
              ).then((module) => module.TamaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TAMARA_CANCEL',
              analyticsName: 'checkout_create_tamara_cancel',
              fragment: ['checkout', ':pid', 'tamara-cancel'],
            },
          },
          // Create checkout Payment Tamara Failure
          {
            path: 'tamara-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tamara-callback" */ '../checkout/views/tamara-callback/tamara-callback.view'
              ).then((module) => module.TamaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TAMARA_FAILURE',
              analyticsName: 'checkout_create_tamara_failure',
              fragment: ['checkout', ':pid', 'tamara-failure'],
            },
          },
        ],
      },
      {
        path: '',
        loadComponent: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "checkout-product-redirect" */ '../checkout/views/checkout-product-redirect/checkout-product-redirect.view'
          ).then((module) => module.CheckoutProductRedirectComponent),
        data: {
          view: 'SESSION',
          step: 'CHECKOUT_PRODUCT_REDIRECT',
          navigation: true,
          analyticsName: 'CheckoutProductRedirect',
          fragment: [''],
        },
      },
    ],
  },

  // Track Order Page
  {
    path: 'order',
    data: {
      view: 'ORDER',
      step: 'ORDER',
      fragment: ['order'],
      analyticsName: 'order',
    },
    canActivate: [LanguageGuard],
    resolve: {},
    children: [
      // Track Public ORDER
      {
        path: 'search',
        pathMatch: 'full',
        loadComponent: () =>
          import(
            /* webpackChunkName: "delivery-tracker" */ '../checkout/views/order-tracker/order-tracker.view'
          ).then((module) => module.OrderTrackerComponent),
        data: {
          view: 'SEARCH-ORDER',
          step: 'INFORMATION',
          fragment: ['order', 'search'],
          analyticsName: 'search-order',
        },
        resolve: {},
      },
      // Delivery Pages
      {
        path: ':id',
        data: {
          view: 'ORDER',
          step: 'ORDER',
          fragment: ['order', ':id'],
          analyticsName: 'order',
        },
        children: [
          // Track Delivery Page
          {
            path: '',
            loadComponent: () =>
              import(
                /* webpackChunkName: "delivery-tracker" */ '../checkout/views/order-tracker/order-tracker.view'
              ).then((module) => module.OrderTrackerComponent),
            canActivate: [AuthGuard],
            data: {
              view: 'ORDER-INFO',
              step: 'INFORMATION',
              analyticsName: 'delivery_details',
              fragment: ['order', ':id'],
            },
          },
        ],
      },

      // Track Delivery Page (error)
      {
        path: '',
        loadComponent: () =>
          import(
            /* webpackChunkName: "delivery-tracker" */ '../checkout/views/order-tracker/order-tracker.view'
          ).then((module) => module.OrderTrackerComponent),
        data: {
          view: 'ORDER',
          step: 'INFORMATION',
          analyticsName: 'order',
          fragment: ['order'],
        },
      },
    ],
  },

  // Products Search Page
  {
    path: 'products',
    pathMatch: 'full',
    loadComponent: () =>
      import(
        /* webpackChunkName: "products-search" */ '../products/search/view/product-search.view'
      ).then((module) => module.ProductSearchViewComponent),
    canActivate: [LanguageGuard, AuthGuard],
    data: {
      view: 'ROOT',
      step: 'PRODUCT_SEARCH',
      navigation: true,
      analyticsName: 'products',
      fragment: ['products'],
    },
    resolve: {},
  },

  // Collection Page
  {
    path: 'c',
    data: {
      view: 'ROOT',
      step: 'COLLECTION_ROOT',
      navigation: true,
      analyticsName: 'collection',
      fragment: ['c'],
    },
    resolve: {},
    children: [
      {
        path: ':section',
        data: {
          view: 'ROOT',
          step: 'COLLECTION',
          navigation: true,
          analyticsName: 'collection',
          fragment: ['c', ':section'],
        },
        children: [
          {
            path: ':key',
            loadComponent: () =>
              import('../products/collection-page/view/collection-page.view').then(
                (c) => c.CollectionPageViewComponent
              ),
            canActivate: [LanguageGuard, AuthGuard],
            data: {
              view: 'ROOT',
              step: 'PRODUCT_SEARCH',
              navigation: true,
              analyticsName: 'products',
              fragment: ['c', ':section', ':key'],
            },
            resolve: {},
          },
        ],
      },
    ],
  },

  // Products Details Page
  {
    path: 'p',
    canActivate: [LanguageGuard, AuthGuard],
    data: {
      view: 'PRODUCT',
      step: 'PRODUCT_ROOT',
      navigation: true,
      analyticsName: 'product',
      fragment: ['p'],
    },
    resolve: {},
    children: [
      {
        path: ':tid',
        pathMatch: 'full',
        loadComponent: () =>
          import(
            /* webpackChunkName: "products-details" */ '../products/details/view/product-details.view'
          ).then((module) => module.ProductDetailsViewComponent),
        canActivate: [LanguageGuard, AuthGuard],
        data: {
          view: 'PRODUCT',
          step: 'PRODUCT_DETAILS',
          navigation: true,
          analyticsName: 'product',
          fragment: ['p', ':tid'],
        },
        resolve: {},
      },
    ],
  },

  // Categorie fallback to home
  {
    path: ':category',
    pathMatch: 'full',
    loadComponent: homePageModuleLoader,
    data: {
      view: 'HOME',
      step: 'CATEGORY',
      fragment: [':category'],
      navigation: true,
      analyticsName: 'home',
    },
    canActivate: [
      // SearchRedirectGuard,
      LanguageGuard,
      AuthGuard,
    ],
    resolve: {},
  },

  // Search Page
  {
    path: 's',
    pathMatch: 'full',
    loadComponent: featuredPageModuleLoader,
    canActivate: [LanguageGuard, AuthGuard],
    data: { view: 'FEATURED', step: 'ROOT', navigation: true, analyticsName: 'featured' },
    resolve: {},
  },

  {
    path: '',
    pathMatch: 'full',
    loadComponent: homePageModuleLoader,
    data: {
      view: 'HOME',
      step: 'ROOT',
      fragment: [''],
      navigation: true,
      analyticsName: 'home',
    },
    canActivate: [
      // SearchRedirectGuard,
      LanguageGuard,
      AuthGuard,
    ],
    resolve: {},
  },

  // Root / Catch All Page
  {
    path: '**',
    loadComponent: () =>
      import(/* webpackChunkName: "not-found" */ '../not-found/not-found.view').then(
        (module) => module.NotFoundComponent
      ),
    canActivate: [LanguageGuard, AuthGuard],
    data: {
      view: 'ERROR',
      step: 'NOT_FOUND',
      fragment: ['not-found'],
      analyticsName: 'not_found',
    },
  },
];
