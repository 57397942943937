/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable max-len */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
  NgZone,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  Router,
  Event,
  RouterLink,
} from '@angular/router';

import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
  tap,
} from 'rxjs';

import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { ScreenSizeInfoState } from '@app/akita/screen/state/screen.store';

import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { AkitaRouterService } from '@app/akita/router/state/router.service';
import { logSearch } from '@app/core/services/google-analytics.lazy';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { AkitaProductsQuery } from '@app/akita/api/products/state/products.query';
import { AkitaProductsService } from '@app/akita/api/products/state/products.service';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { AkitaStartingPricesQuery } from '@app/akita/api/starting-prices/state/starting-prices.query';
import { RemoveIconComponent } from '../icons/generated/remove/remove.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SearchGlassIconComponent } from '../icons/generated/search-glass/search-glass.component';
import { toUrlSlug } from '@app/shared/utils/url.utils';

export interface AutocompleteSuggestion {
  productId: number;
  title: string;
}

const AUTOCORRECT_AR = {
  ايفون: 'آيفون', // iPhone
  لابتوب: 'معالج',
  'لاب توب': 'معالج',
  'ساعة ابل': 'ساعة آبل',
  جوالات: 'هاتف',
  'جوال ايفون': 'آيفون',
  جوال: 'هاتف',
  جلكسي: 'غالاكسي',
  تابلت: 'تاب',
  ايباد: 'آيباد',
  ابل: 'آبل',
};

const TRANSLATION_MAP = {
  هونر: 'Honor',
  هواوي: 'Huawei',
  هاتف: 'Phone',
  مجدد: 'Renewed',
  'ماك بوك': 'MacBook',
  لابتوب: 'Laptop',
  'لاب توب': 'Laptop',
  'سوني 5': 'Sony 5',
  سوني: 'Sony',
  سماعات: 'Headphones',
  سامسونج: 'Samsung',
  'ساعة ابل': 'Apple Watch',
  ساعات: 'Watches',
  جولات: 'Tours',
  جول: 'Tour',
  جوالات: 'Mobiles',
  'جوال ايفون': 'iPhone Mobile',
  جوال: 'Mobile',
  جلكسي: 'Galaxy',
  جالكسي: 'Galaxy',
  تابلت: 'Tablet',
  تاب: 'Tab',
  بيسي: 'PC',
  'بي سي': 'PC',
  بلاستيشن: 'PlayStation',
  ايفون14: 'iPhone 14',
  ايفون١٤: 'iPhone 14',
  ايفون13: 'iPhone 13',
  ايفون١٣: 'iPhone 13',
  ايفون12: 'iPhone 12',
  ايفون11: 'iPhone 11',
  ايفون١١: 'iPhone 11',
  'ايفون مجدد': 'Renewed iPhone',
  'ايفون برو ماكس': 'iPhone Pro Max',
  'ايفون اكس ماكس': 'iPhone XS Max',
  'ايفون اكس ار': 'iPhone XR',
  'ايفون اكس': 'iPhone X',
  'ايفون xr': 'iPhone XR',
  'ايفون x': 'iPhone X',
  'ايفون 8': 'iPhone 8',
  'ايفون 7': 'iPhone 7',
  'ايفون ٧': 'iPhone 7',
  'ايفون 6': 'iPhone 6',
  'ايفون 14برو ماكس': 'iPhone 14 Pro Max',
  'ايفون 14 برو ماكس': 'iPhone 14 Pro Max',
  'ايفون ١٤ برو ماكس': 'iPhone 14 Pro Max',
  'ايفون 14 برو': 'iPhone 14 Pro',
  'ايفون 14': 'iPhone 14',
  'ايفون ١٤': 'iPhone 14',
  'ايفون 13 برو ماكس': 'iPhone 13 Pro Max',
  'ايفون 13': 'iPhone 13',
  'ايفون ١٣': 'iPhone 13',
  'ايفون 12': 'iPhone 12',
  'ايفون ١٢': 'iPhone 12',
  'ايفون 11': 'iPhone 11',
  'ايفون ١١': 'iPhone 11',
  'ايفون 10': 'iPhone 10',
  ايفون: 'iPhone',
  ايفوان: 'iPhones',
  ايفن: 'iPhones',
  ايبد: 'iPad',
  'ايباد ميني': 'iPad Mini',
  'ايباد برو': 'iPad Pro',
  'ايباد ايفون': 'iPad iPhone',
  'ايباد اير': 'iPad Air',
  'ايباد ابل': 'Apple iPad',
  ايباد: 'iPad',
  اي: 'i',
  ابل: 'Apple',
  ا: 'A',
  'آيفون 14': 'iPhone 14',
  'آيفون 11': 'iPhone 11',
  آيفون: 'iPhone',
  آيباد: 'iPad',
};

const AUTOTRANSLATE_NUMBERS = {
  '٠': '0',
  '١': '1',
  '٢': '2',
  '٣': '3',
  '٤': '4',
  '٥': '5',
  '٦': '6',
  '٧': '7',
  '٨': '8',
  '٩': '9',
};

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.template.html',
  styleUrls: ['./search-box.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    SearchGlassIconComponent,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    RouterLink,
    RemoveIconComponent,
    NgFor,
    AsyncPipe,
  ],
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  @Input() public searchType?: string | null;

  public searchQuery: string;

  public readonly queryParamsToKeep$: Observable<Params>;

  private readonly searchQueryFragmentsSubject: BehaviorSubject<string>;
  public searchQueryFragments$: Observable<string>;

  public inFocus: boolean;

  private readonly inFocusSubject: BehaviorSubject<boolean>;
  public inFocus$: Observable<boolean>;

  public params: Params;
  public queryParams: Params;
  public routeData: Params;
  public rtlMode: boolean;
  public language: string;

  public isBrowser: boolean;

  private readonly urlObserver: Subscription | null;

  @ViewChild('searchField')
  public searchFieldElement?: ElementRef | null;

  public lessThan$: Observable<ScreenSizeInfoState>;
  public biggerThan$: Observable<ScreenSizeInfoState>;
  public searchUrlFragment$: Observable<Array<string>>;

  public autocomplete$: Observable<Array<string>>;
  public isFilteredHints: boolean;
  public queryModels: Array<string> = [];

  private readonly subscription: Subscription | null;
  private readonly destroySubject: Subject<void>;

  constructor(
    private readonly zone: NgZone,
    private readonly router: Router,
    private readonly analytics: GoogleAnalyticsService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaRouterService: AkitaRouterService,
    private readonly akitaProductsQuery: AkitaProductsQuery,
    private readonly akitaProductsService: AkitaProductsService,
    private readonly akitaStartingPricesQuery: AkitaStartingPricesQuery,
    private readonly translateService: TranslocoService
  ) {
    this.destroySubject = new Subject();
    this.subscription = new Subscription();
    this.isBrowser = this.akitaRouterQuery.isBrowser;
    this.params = {};
    this.queryParams = {};
    this.routeData = {};
    this.rtlMode = false;
    this.inFocus = false;
    this.lessThan$ = this.akitaScreenQuery.selectLessThan();
    this.biggerThan$ = this.akitaScreenQuery.selectBiggerThan();
    this.searchQuery = '';
    this.isFilteredHints = false;
    this.language = this.translateService.getActiveLang() || 'en';

    this.queryParamsToKeep$ = this.akitaRouterQuery.selectQueryParamsToKeep();

    this.inFocusSubject = new BehaviorSubject(false);
    this.inFocus$ = this.inFocusSubject.asObservable();

    this.searchQueryFragmentsSubject = new BehaviorSubject('');
    this.searchQueryFragments$ = this.searchQueryFragmentsSubject.asObservable();

    this.autocomplete$ = combineLatest([
      this.searchQueryFragments$,
      this.akitaStartingPricesQuery.selectStartingPrices(),
      this.akitaProductsQuery.selectProductSearchHints(),
    ]).pipe(
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      debounceTime(500),
      distinctUntilChanged(),
      map(([query, models, hints]) => {
        // add all the model names to the hints
        const combinedHints = [...hints];
        if (models.length) {
          this.queryModels = models
            .map((model) => model.model)
            .filter((hint) => Boolean(hint.toLowerCase().includes(query)))
            .slice(0, 3);
          console.log(this.queryModels);
          combinedHints.unshift(...this.queryModels);
        }

        query = this.autoCorrectQuery(query.toLowerCase());
        return this.searchQuery.length
          ? combinedHints.filter((hint) => Boolean(hint.toLowerCase().includes(query)))
          : new Array(0); // if no fragments, return empty array
      }),
      tap((results) => (this.isFilteredHints = Boolean(results.length)))
    );

    this.searchUrlFragment$ = this.akitaRouterQuery.selectSearchUrlFragment();

    // Listen to Navigation End events
    this.urlObserver = this.router.events.pipe(takeUntil(this.destroySubject)).subscribe({
      next: ($event: Event) => {
        if ($event instanceof NavigationEnd) {
          let route = this.activatedRoute.snapshot;
          while (route.firstChild) {
            route = route.firstChild;
          }
          this.onNavigationEnd(
            route.params || {},
            route.queryParams || {},
            route.data || {}
          );
        }
      },
    });

    this.subscription.add(
      this.akitaProductsService.fetchProductSearchHints().subscribe({
        next: () => {},
      })
    );
  }

  public ngOnInit(): void {
    let route = this.activatedRoute.snapshot;
    while (route.firstChild) {
      route = route.firstChild;
    }
    this.onNavigationEnd(route.params || {}, route.queryParams || {}, route.data || {});
  }

  public onNavigationEnd(params: Params, queryParams: Params, routeData: Params): void {
    this.params = params || {};
    this.queryParams = queryParams || {};
    this.routeData = routeData || {};
    this.rtlMode = routeData.rtl || false;
    this.searchQuery = (queryParams.text || '').replace(/\+/g, ' ');
    this.changeDetector.markForCheck();
  }

  public search(queryText: string, searchHint?: boolean | null): void {
    if (this.searchFieldElement) {
      this.searchFieldElement.nativeElement.blur();
    }

    // autoCorrectQuery on the search query to API
    queryText = this.autoCorrectQuery(queryText);

    logSearch(
      this.analytics.logEventWrapper,
      this.shortenString(queryText),
      this.akitaProductsQuery.validateLocale().country || 'SA'
    );

    // model hint search redirects to collection pages
    if (searchHint && this.queryModels.includes(queryText)) {
      this.akitaRouterService.navigate(
        ['c', 'model', toUrlSlug(queryText.toLowerCase())],
        {
          ...this.queryParams,
          ['text']: undefined,
        }
      );
    } else {
      // normal search or item hint search
      const fragment = this.akitaRouterQuery.getPhoneSearchFragment();

      this.zone.run(() => {
        this.akitaRouterService.navigate(
          fragment,
          {
            ...this.queryParams,
            text: queryText || undefined,

            // reset all the filters on new searches
            ['min_price']: undefined,
            ['max_price']: undefined,
            ['global']: undefined,
            ['sort_by']: undefined,
            ['posted']: undefined,
            ['models']: undefined,
            ['colors']: undefined,
            ['storages']: undefined,
            ['category']: undefined,
            ['brands']: undefined,
            ['cpu']: undefined,
            ['ram']: undefined,
            ['screen_size']: undefined,
            ['graphics']: undefined,
            ['storage_type']: undefined,
            ['launch_date']: undefined,
          },
          {
            queryParamsHandling: 'merge',
            preserveFragment: true,
          }
        );
      });
    }
  }

  public getClearSearchQuery(): Params {
    return {
      ...this.queryParams,
      ['text']: undefined,
    };
  }

  public close(): void {
    this.zone.run(() => {
      this.inFocus = false;
      this.changeDetector.markForCheck();
    });
  }

  public openSearchInput(): boolean {
    if (!this.inFocus) {
      this.searchFieldElement?.nativeElement.focus();
      this.inFocusSubject.next(true);
      this.inFocus = true;
    }
    return false;
  }

  public switchFocus(value: boolean) {
    setTimeout(() => {
      this.inFocus = value;
      this.inFocusSubject.next(value);

      if (!value) {
        // on focus out
        // Log search event
        logSearch(
          this.analytics.logEventWrapper,
          this.shortenString(this.searchQuery),
          this.akitaProductsQuery.validateLocale().country || 'SA'
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 300);
  }

  public updateQueryFragments($event: any) {
    if ($event.target.value) {
      if (this.language === 'ar') {
        // this.searchQuery = this.autoCorrectQuery($event.target.value);
        this.searchQueryFragmentsSubject.next(this.searchQuery);
      } else {
        this.searchQueryFragmentsSubject.next($event.target.value);
      }
    }
  }

  public autoCorrectQuery(query: string): string {
    for (const [key, value] of Object.entries(AUTOCORRECT_AR)) {
      query = query.replace(key, value);
    }

    for (const [key, value] of Object.entries(AUTOTRANSLATE_NUMBERS)) {
      query = query.replace(new RegExp(this.escapeRegExp(key), 'g'), value);
    }
    // iPhone11 => iPhone 11
    if (query.includes('آيفون')) {
      query = query.replace(/(آيفون)(\d+)/, '$1 $2');
    }
    if (this.language === 'en') {
      for (const [key, value] of Object.entries(TRANSLATION_MAP)) {
        query = query.replace(key, value).toLocaleLowerCase();
      }
    }
    return query;
  }

  private escapeRegExp(str: string): string {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  public productFragment(id: number | null): Array<string> {
    return this.akitaRouterQuery.getProductUrlFragment(`${id}`);
  }

  private shortenString(str: string): string {
    // shorten the string for analytics not over 100 characters
    if (str.length > 90) {
      return str.slice(0, 80) + '...';
    }
    return str;
  }

  public trackBySuggestion(index: number, value?: string | null): string {
    return `${value || index}`;
  }

  public ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();

    if (this.urlObserver) {
      this.urlObserver.unsubscribe();
    }
  }
}
